import React, { useState, useContext } from "react";
import { Form, Input, Button, Checkbox, message } from "antd";
import { navigate } from "gatsby";
import Layout from "../components/Layout";
import { login } from "../utils/api";
import { setCookie } from "../utils/setCookie";
import { AuthDispatchContext } from "../context/AuthContextProvider";
import { eraseAllCookies } from "../utils/eraseAllCookies";
import "../styles/components/admin-login.scss";

const Login = () => {
  const AuthDispatch = useContext(AuthDispatchContext);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = values => {
    const { username, password, remember } = values;
    eraseAllCookies();
    setIsLoading(true);
    login({ username, password })
      .then(async r => {
        if (r.ok) {
          const { user, accessToken } = await r.json();
          if (remember) setCookie("accessToken", accessToken);
          AuthDispatch({ type: "set", payload: { user, accessToken } });
          if (user.role === "admin") navigate("/dashboard");
          else message.error("Your email or password seems to be wrong");
        } else {
          message.error("Your email or password seems to be wrong");
        }
      })
      .catch(err => {
        console.log(err);
        message.error("Your email or password seems to be wrong");
      })
      .finally(() => {
        setIsLoading(false);
      });
    // console.log("Success:", values);
  };

  const onFinishFailed = errorInfo => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Layout>
      <div className="admin-login">
        <h3>Login</h3>
        <Form
          form={form}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: "Please input your username" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password" }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="remember"
            valuePropName="checked"
            className="admin-login__remember"
          >
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              className="main-btn"
              style={{ maxWidth: "100%", height: 50, borderRadius: "4px" }}
              htmlType="submit"
              loading={isLoading}
            >
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Layout>
  );
};

export default Login;
